<template>
    <div>
        <section class="blockElement space" v-if="Object.keys(store.rewardsDetails).length && step==1">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">
                        <h3 class="medium f-28 darkblueColor my-3">{{$t('rewards.rewardstitle')}}</h3>
                        <div class="bordergS bg-white br-12 py-4 px-2 mb-4">
                            <div class="mx-3 my-0 mb-3">
                                <!-- <div class="flex-between cardBorder-bottom pb-2 mb-3">
                                    <h6 class="mb-0 darkblueColor medium">Total earned</h6>
                                    <h6 class="mb-0 darkblueColor f-18 semibold">$56</h6>
                                </div> -->
                                <div class="flex-between">
                                    <h6 class="mb-0 darkblueColor medium">{{$t('rewards.rewardscontent1')}}</h6>
                                    <h6 class="mb-0 darkblueColor f-18 semibold">{{store.rewardsDetails.totalCopiers || '0'}}</h6>
                                </div>
                            </div>
                            <div class="py-2 px-3 bg-card flex-between br-12">
                                <h6 class="mb-0 darkblueColor medium">{{$t('rewards.rewardscontent2')}} </h6>
                                <div class="d-flex align-items-center">
                                    <h6 class="mb-0 darkblueColor f-18 semibold me-3">${{store.rewardsDetails.outstandingPayment || '0'}}</h6>
                                    <button class="button fillBtn zulu_btn small" @click="withdrawalFunds();step=4" v-if="parseInt(store.rewardsDetails.outstandingPayment) >= 100">{{$t('rewards.rewardscontent3')}} </button>
                                    <button v-else class="button fillBtn zulu_btn small disabled">{{$t('rewards.rewardscontent3')}} </button>
                                </div>
                            </div>
                        </div>
                        <div class="bordergS bg-white boxed p-0 mb-md-5 mb-4">
                            <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between p-3 bordergSB" @click="withdrawalHistory();step=2">
                                <div class="d-flex align-items-center">
                                    <div class="me-2">
                                        <v-lazy-image class="d-block" src="/assets/images/globalBack.svg" :alt="$t('rewards.rewardscontent33')" />
                                    </div>
                                    <div class="d-block">
                                        <h6 class="mb-0">{{$t('rewards.rewardscontent4')}}</h6>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <vue-feather type="chevron-right" size="17"></vue-feather>
                                </div>
                            </a>
                            <a href="javascript:void(0);" class="d-flex align-items-center justify-content-between p-3" @click="rewardsHistory();step=3">
                                <div class="d-flex align-items-center">
                                    <div class="me-2">
                                        <v-lazy-image class="d-block" src="/assets/images/globalBack.svg" :alt="$t('rewards.rewardscontent34')" />
                                    </div>
                                    <div class="d-block">
                                        <h6 class="mb-0">{{$t('rewards.rewardscontent5')}}</h6>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <vue-feather type="chevron-right" size="17"></vue-feather>
                                </div>
                            </a>
                        </div>
                        <div class="adffiliatePortal text-center">
                            <h6 class="neutralGrey mb-3 medium"><span>{{$t('rewards.rewardscontent6')}}</span> <br> 
                                <span>{{$t('rewards.rewardscontent7')}} </span></h6>
                            <a :href="`//www.zulutrade.com/partners/?token=${store.user?.access_token}&refresh_token=${store.user?.refresh_token}`" target="_blank" class="button fillBtn zulu_btn border-button px-md-5">{{$t('rewards.rewardscontent8')}} </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space" v-if="step==2">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">
                        <a href="javascript:void(0)" class="f-14 graybtnBg py-1 br-20 px-2 medium d-inline-flex align-items-center" @click="step=1;historyFilter=''"><vue-feather class="me-1" size="14" type="chevron-left"></vue-feather>{{$t('rewards.rewardscontent9')}} </a>
                        <h3 class="medium f-28 darkblueColor my-3">{{$t('rewards.rewardscontent10')}} </h3>
                        <div class="bordergS bg-white br-12 mt-2 p-3" v-if="Object.keys(store.withdrawalHistory).length && store.withdrawalHistory.content.length">
                            <div class="form-group smalldate d-flex justify-content-end">
                                <input type="date" class="form-control" :placeholder="$t('rewards.rewardscontent11')" v-model="historyFilter"/>
                            </div>
                            <div class="listChild" v-if="historyFilterData().length">
                                <div class="cardBorder-bottom pb-3 mb-3 d-flex align-items-center justify-content-between" v-for="item,key in historyFilterData()" :key="key">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3 rounded bordergS p-2">
                                            <v-lazy-image v-if="item.paymentAccountType=='Bank Account'" class="d-block" width="30" height="30" src="/assets/images/bankWallet.png" :alt="$t('rewards.rewardscontent35')" />
                                            <v-lazy-image v-else-if="item.paymentAccountType=='Crypto Wallet'" class="d-block" width="30" height="30" src="/assets/images/walletWallet.png" :alt="$t('rewards.rewardscontent36')" />
                                            <v-lazy-image v-else-if="item.paymentAccountType=='PayPal Account'" class="d-block" width="30" height="30" src="/assets/images/paypalWallet.png" :alt="$t('rewards.rewardscontent37')" />
                                        </div>
                                        <div class="d-block">
                                            <h6 class="mb-0 darkblueColor d-flex align-items-center">{{item.paymentAccountType}} {{$t('rewards.rewardscontent12')}}   <span class="status ms-3" :class="[{'yellow':item.paymentStatus=='Requested'},{'green':item.paymentStatus=='Executed'},{'red':item.paymentStatus=='Declined'},{'red':item.paymentStatus=='Cancelled'}]">{{item.paymentStatus || ''}}</span></h6>
                                            <p class="mb-0 gray f-13">{{historyDateFormat(item.requestedDate)}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <h6 class="mb-0 medium me-3">{{item.totalPayableAmount || 0}}$</h6>
                                        <button class="button fillBtn zulu_btn small redBG" v-if="item.paymentStatus=='Requested'" @click="confirmCancel=true">{{$t('rewards.rewardscontent13')}} </button>
                                    </div>
                                </div>
                            </div>
                            <Nodata v-else></Nodata>
                        </div>
                        <Nodata v-else></Nodata>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space" v-if="step==3">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">
                        <a href="javascript:void(0)" class="f-14 graybtnBg py-1 br-20 px-2 medium d-inline-flex align-items-center" @click="step=1"><vue-feather class="me-1" size="14" type="chevron-left"></vue-feather>{{$t('rewards.rewardscontent9')}}</a>
                        <h3 class="medium f-28 darkblueColor my-3">{{$t('rewards.rewardscontent14')}} </h3>
                        <ul class="bordergS bg-white br-12 mt-2 p-3 listReward" v-if="Object.keys(store.rewardsHistory).length && store.rewardsHistory.content.length">
                            <li class="cardBorder-bottom pb-3 mb-3 d-flex align-items-center justify-content-between" v-for="item,key in store.rewardsHistory.content" :key="key">
                                <div class="d-flex align-items-center">
                                    <p class="mb-0 neutralGrey me-md-5 me-3">{{dateFormat(item.period)}}</p>
                                    <h6 class="mb-0">{{item.username || ''}}</h6>
                                </div>
                                <p class="mb-0 neutralGrey medium">${{(item.amount || 0).toFixed(2)}}</p>
                            </li>
                        </ul>
                        <Nodata v-else></Nodata>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space" v-if="step==4">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10">
                        <a href="javascript:void(0)" class="f-14 graybtnBg py-1 br-20 px-2 medium d-inline-flex align-items-center" @click="step=1"><vue-feather class="me-1" size="14" type="chevron-left"></vue-feather>{{$t('rewards.rewardscontent9')}}</a>
                        <h3 class="medium f-28 darkblueColor my-3">{{$t('rewards.rewardscontent15')}} </h3>
                        <ul class="bordergS bg-white br-12 mt-2 p-3 listReward">
                            <li class="cardBorder-bottom pb-3 mb-3">
                                <label class="cutomChechBox radio mb-0" for="wallet">
                                    <span class="d-flex align-items-center">
                                        <span class="mx-2"><v-lazy-image src="/assets/images/walletWallet.png" :alt="$t('rewards.rewardscontent38')" /></span>
                                        <h6 class="mb-0 f-17">{{$t('rewards.rewardscontent16')}} </h6>
                                    </span>
                                    <input type="radio" name="withdraw" id="wallet" value="wallet" v-model="withdraw_method"/>
                                    <span class="checkmark"></span>
                                </label>
                                <div class="dropdown_menu_animated position-relative box-shadow-none" :class="withdraw_method=='wallet'?'show':''">
                                    <div class="form-group mt-3 max-500">
                                        <Form @submit="paymentRequest">
                                            <Field v-model="walletAddress" :placeholder="$t('rewards.rewardscontent17')" type="text" name="wallet id" class="form-control" aria-required="true" aria-invalid="true" rules="required"/>
                                            <ErrorMessage class="text-danger d-block f-12" name="wallet id" />
                                            <p class="text-danger d-block f-12 mb-0" v-if="!addressValid">{{$t('rewards.rewardscontent18')}} </p>
                                            <button type="submit" class="button fillBtn zulu_btn small mt-3 px-md-5">{{$t('rewards.rewardscontent19')}} </button>
                                        </Form>
                                    </div>
                                </div>
                            </li>
                            <li class="cardBorder-bottom pb-3 mb-3">
                                <label class="cutomChechBox radio mb-0" for="paypal">
                                    <span class="d-flex align-items-center">
                                        <span class="mx-2"><v-lazy-image src="/assets/images/paypalWallet.png" :alt="$t('rewards.rewardscontent39')" /></span>
                                        <h6 class="mb-0 f-17">{{$t('rewards.rewardscontent20')}} </h6>
                                    </span>
                                    <input type="radio" name="withdraw" id="paypal" value="paypal" v-model="withdraw_method"/>
                                    <span class="checkmark"></span>
                                </label>
                                <div class="dropdown_menu_animated position-relative box-shadow-none" :class="withdraw_method=='paypal'?'show':''">
                                    <div class="form-group mt-3 max-500">
                                        <Form @submit="paymentRequest">
                                            <Field type="text" class="form-control" name="Paypal Email" :placeholder="$t('rewards.rewardscontent21')" :value="store.userDetail.email" rules="required" readOnly/>
                                            <ErrorMessage class="text-danger d-block f-12" name="Paypal Email" />
                                            <button type="submit" class="button fillBtn zulu_btn small mt-3 px-md-5">{{$t('rewards.rewardscontent22')}} </button>
                                        </Form>
                                    </div>
                                </div>
                            </li>
                            <li class="cardBorder-bottom pb-3">
                                <label class="cutomChechBox radio mb-0" for="bank">
                                    <span class="d-flex align-items-center">
                                        <span class="mx-2"><v-lazy-image src="/assets/images/bankWallet.png" :alt="$t('rewards.rewardscontent40')" /></span>
                                        <h6 class="mb-0 f-17">{{$t('rewards.rewardscontent23')}} </h6>
                                    </span>
                                    <input type="radio" name="withdraw" id="bank" value="bank" v-model="withdraw_method"/>
                                    <span class="checkmark"></span>
                                </label>
                                <div class="dropdown_menu_animated position-relative box-shadow-none" :class="withdraw_method=='bank'?'show':''">
                                    <p class="f-14 neutralGrey medium mb-2 mt-3" v-if="internalBankAcc.length">{{$t('rewards.rewardscontent24')}} </p>
                                    <!-- Object.keys(store.existingPayment).length && store.existingPayment.data && store.existingPayment.data.records.length || internalBankAcc.length || (internalBankAcc.length && Object.keys(store.existingPayment).length && store.existingPayment.data && store.existingPayment.data.records.length) -->
                                    <Form @submit="paymentRequest">
                                        <!-- <div class="lenght" v-if="Object.keys(store.existingPayment).length && store.existingPayment.data && store.existingPayment.data.records.length">
                                            <div class="d-flex align-items-center justify-content-between cardBorder listBankAccount flex-wrap" :class="bankAccount.bankAccountNo==item.bankAccountNo?'active':''" v-for="item,key in store.existingPayment.data.records" :key="key">
                                                <label class="cutomChechBox radio mb-0" :for="item.bankAccountNo">
                                                    <span class="neutralGrey f-17 medium">{{item.bankAccountNo}}</span>
                                                    <Field type="radio" v-model="bankAccount" :id="item.bankAccountNo" name="Bank Account" :value="item" @change="isOpen=''" rules="required"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                                <a href="javascript:void(0)" class="secondary f-14 viewDetail" @click="detailDiv(item.bankAccountNo)">View details</a>
                                                <ul class="dropdown_menu_animated position-relative box-shadow-none show w-100 mt-4" v-if="isOpen==item.bankAccountNo">
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">Beneficiary Name:</span> <span>{{item.bankBeneficiary}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">Bank Country:</span> <span>{{item.bankCountryIso}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">Bank Name:</span> <span>{{item.bank}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">Bank Routing Number:</span> <span>{{item.bankRoutingNumber}}</span></li>
                                                </ul>
                                            </div>
                                        </div> -->
                                        <div class="lenght" v-if="internalBankAcc.length">
                                            <div class="d-flex align-items-center justify-content-between cardBorder flex-wrap listBankAccount" :class="bankAccount.accountNumber==item.accountNumber?'active':''" v-for="item,key in (internalBankAcc || [])" :key="key">
                                                <label class="cutomChechBox radio mb-0" :for="item.accountNumber">
                                                    <span class="neutralGrey f-17 medium">{{item.accountNumber}}</span>
                                                    <Field type="radio" v-model="bankAccount" name="Bank Account" :id="item.accountNumber" :value="item" @change="isOpen=''" rules="required"/>
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="d-flex align-items-center">
                                                    <a href="javascript:void(0)" class="secondary f-14 viewDetail" @click="detailDiv(item.accountNumber)">{{$t('rewards.rewardscontent25')}} </a>
                                                    <a href="#" class="ms-3 secondary" v-if="bankAccount.accountNumber==item.accountNumber" @click="isEdit = item; isEdit['index'] = key;"><vue-feather type="edit" size="19"></vue-feather></a>
                                                </div>
                                                <ul class="dropdown_menu_animated position-relative box-shadow-none show w-100 mt-4" v-if="isOpen==item.accountNumber">
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">{{$t('rewards.rewardscontent26')}} </span> <span>{{item.beneficiaryName}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">{{$t('rewards.rewardscontent27')}}</span> <span>{{item.countryIso}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">{{$t('rewards.rewardscontent28')}} </span> <span>{{item.bankName}}</span></li>
                                                    <li class="list-group-item d-flex justify-content-between align-items-center"><span class="gray">{{$t('rewards.rewardscontent29')}} </span> <span>{{item.code}}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <ErrorMessage class="text-danger d-block f-12" name="Bank Account" />
                                        <div class="d-flex align-items-center mt-3">
                                            <button v-if="Object.keys(store.existingPayment).length && store.existingPayment.data && store.existingPayment.data.records.length || internalBankAcc.length || (internalBankAcc.length && Object.keys(store.existingPayment).length && store.existingPayment.data && store.existingPayment.data.records.length)" type="submit" class="button fillBtn zulu_btn small me-3 px-md-5">Send funds</button>
                                            <a href="javascript:void(0)" class="button fillBtn zulu_btn border-button px-md-5" @click="showAddBank=true">{{$t('rewards.rewardscontent30')}} </a>
                                        </div>
                                    </Form>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <AddBank v-if="showAddBank || Object.keys(isEdit).length > 0" :edit="isEdit"></AddBank>
        
        <div class="modal show fade" style="display: block;" v-if="confirmCancel">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header border-0">
                        <h5 class="modal-title">{{$t('rewards.rewardscontent31')}} </h5>
                        <button type="button" class="btn-close" @click="confirmCancel=false"></button>
                    </div>
                    <div class="modal-body p-4 border-bottom text-center">
                        <p class="mb-0">{{$t('rewards.rewardscontent32')}} </p>
                    </div>
                    <div class="modal-footer border-0 justify-content-end">
                        <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" @click="confirmCancel=false">{{$t('rewards.rewardscontent9')}}</a>
                        <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="cancelRequest()">{{$t('rewards.rewardscontent13')}} </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import AddBank from "@/views/dashboard-new/pages/add-bank.vue";
    import moment from "moment";
    const WAValidator = require('@swyftx/api-crypto-address-validator');
    import { Form, Field, ErrorMessage } from "vee-validate";
    import { useToast } from "vue-toastification";
    const toast = useToast();
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                bankAccount: '',
                step: 1,
                withdraw_method: '',
                showAddBank: false,
                isEdit: {},
                internalBankAcc: [],
                isOpen: '',
                walletAddress: '',
                addressValid:true,
                historyFilter: '',
                confirmCancel: false,
            };
        },
        components: { Form, Field, ErrorMessage, AddBank },
        methods: {
            detailDiv(account){
                if (this.isOpen == account) {
                    this.isOpen = "";
                } else {
                    this.isOpen = account;
                }
            },
            historyFilterData(){
                if(this.historyFilter){
                    return this.store.withdrawalHistory.content.filter(val=>{
                        let date = moment (val.requestedDate,'DD-MM-YYYY hh-mm-ss').format('YYYY-MM-DD')
                        return this.historyFilter==date
                    })
                } else {
                    return this.store.withdrawalHistory.content
                }
            },
            dateFormat(date){
                return moment(date,'YYYY-M').format("MMM YYYY")
            },
            historyDateFormat(date){
                return moment (date,'DD-MM-YYYY hh-mm-ss').format('DD MMM, hh:mm A')
            },
            withdrawalHistory(){
                this.store.getWithdrawalHistory({},false)
            },
            rewardsHistory(){
                this.store.getRewardsHistory({},false)
            },
            withdrawalFunds(){
                this.store.getWithdrawalFunds({},false)
                // this.store.getExistingPayment({},false)
            },
            paymentRequest(){
                if(this.store.rewardsDetails.outstandingPayment > 0){
                    let payLoad = {
                        "paymentAccountTypeId": 1,
                        "outstandingAmount" : this.store.rewardsDetails.outstandingPayment
                    }
                    if(this.withdraw_method=='wallet'){
                        const valid = WAValidator.validate(this.walletAddress, 'Tether');
                        if (valid) {
                            this.addressValid = true
                            payLoad.paymentAccountTypeId = '8';
                            payLoad['cryptoCoinId'] = '8';
                            payLoad['walletAddress'] = this.walletAddress; 
                        } else {
                            this.addressValid = false 
                        }
                    } else if(this.withdraw_method=='paypal'){
                        payLoad.paymentAccountTypeId = '2';
                        payLoad['email'] = this.store.userDetail.email;
                    } else if(this.withdraw_method=='bank'){
                        payLoad.paymentAccountTypeId = '1';
                        if(this.bankAccount.internal){
                            let bankAccount = {...this.bankAccount}
                            delete bankAccount.internal
                            payLoad = { ...payLoad, ...bankAccount }
                        }else{
                            payLoad['accountNumber'] = this.bankAccount.bankAccountNo;
                            payLoad['bank'] = this.bankAccount.bank;
                            payLoad['beneficiaryName'] = this.bankAccount.bankBeneficiary;
                            payLoad['countryIso'] = this.bankAccount.bankCountryIso;
                        }
                    }
                    this.store.getWithdrawalPayment(payLoad,false).then((res)=>{
                        if(res){
                            this.step = 2;
                        }
                    })
                } else {
                    toast("Insufficient balance to withdraw", { type: "error" });
                }
            },
            cancelRequest(){
                this.store.cancelWithdrawalRequest({},false).then((res)=>{
                    if(res){
                        this.confirmCancel = false;
                        this.step = 2;
                        this.withdrawalHistory()
                    }
                })
            }
        },
        created() {
            this.store.getRewardsDetails({},true)
        },
    };
</script>
