<template>
    <div class="modal fade show" style="display: block;">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{Object.keys(edit).length > 0? $t('bankAccount.bankAccountcontent11') : $t('bankAccount.bankAccountcontent12') }} {{$t('bankAccount.bankAccountcontent1')}} </h5>
                    <button type="button" class="btn-close" @click="$parent.showAddBank = false;$parent.isEdit = {}"></button>
                </div>
                <div class="modal-body pt-3">
                    <Form @submit="addbank">
                        <div class="form-group">
                            <!-- Field make editable -->
                            <label for="beneficiary-name" class="col-form-label">{{$t('bankAccount.bankAccountcontent2')}}</label>
                            <Field type="text" class="form-control" name="Beneficiary Name" v-model="bank.beneficiaryName" rules="required"/>
                            <ErrorMessage class="text-danger f-12" name="Beneficiary Name" />
                        </div>
                        <div class="form-group">
                            <label for="Account Number" class="col-form-label">{{$t('bankAccount.bankAccountcontent3')}} </label>
                            <Field type="text" class="form-control" id="Account Number" name="Account Number" v-model="bank.accountNumber" rules="required"/>
                            <ErrorMessage class="text-danger f-12" name="Account Number" />
                        </div>
                        <div class="form-group">
                            <label for="swift-code" class="col-form-label">{{$t('bankAccount.bankAccountcontent4')}} </label>
                            <Field type="text" class="form-control" id="swift-code" name="Swift Code" v-model="bank.code" rules="required"/>
                            <ErrorMessage class="text-danger f-12" name="Swift Code" />
                        </div>
                        <div class="form-group">
                            <label for="bank" class="col-form-label">{{$t('bankAccount.bankAccountcontent5')}} </label>
                            <Field type="text" class="form-control" id="bank" name="Bank Name" v-model="bank.bankName" rules="required"/>
                            <ErrorMessage class="text-danger f-12" name="Bank Name" />
                        </div>
                        <div class="form-group">
                            <label for="Residence-Country" class="col-form-label">{{$t('bankAccount.bankAccountcontent6')}} </label>
                            <Field as="select" class="form-control" name="Residence Country" v-model="bank.countryIso" id="Residence-Country"  rules="required">
                                <option value="" disabled="disabled">{{$t('bankAccount.bankAccountcontent7')}} </option>
                                <option :value="list.isoCode" v-for="(list,index) in (store.countriesList || [])" :key="index">{{ list.name }}</option>
                            </Field>
                            <ErrorMessage class="text-danger f-12" name="Residence Country" />
                        </div>
                        <div class="d-flex border-0 justify-content-center">
                            <a class="button fillBtn border-button zulu_btn d-flex px-md-5 me-3" @click="$parent.showAddBank = false;$parent.isEdit = {}">{{$t('bankAccount.bankAccountcontent8')}} </a>
                            <button type="submit" class="button fillBtn zulu_btn d-flex px-md-5" v-if="Object.keys(edit).length > 0">{{$t('bankAccount.bankAccountcontent9')}}</button>
                            <button type="submit" class="button fillBtn zulu_btn d-flex px-md-5" v-else>{{$t('bankAccount.bankAccountcontent10')}} </button>
                        </div>
                    </Form>
                </div>
                <!-- <div class="modal-footer border-0 justify-content-center">
                    <button type="button" class="button fillBtn border-button zulu_btn d-flex px-md-5" @click="$parent.showAddBank = false;$parent.isEdit = {}">Close</button>
                    <button type="button" class="button fillBtn zulu_btn d-flex px-md-5" @click="addbank()" v-if="Object.keys(edit).length > 0">edit Bank</button><button type="button" class="button fillBtn zulu_btn d-flex px-md-5" @click="addbank()" v-else>Add Bank</button>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        name: "add-bank",
        props: ["edit"],
        data() {
            return {
                bank: {
                    beneficiaryName: "",
                    accountNumber: "",
                    bankName: "",
                    code: "",
                    countryIso: "",
                    /*"bankAddress": "",*/
                    internal: true,
                },
            };
        },
        components: { Form, Field, ErrorMessage },
        methods: {
            addbank() {
                if (Object.keys(this.edit).length > 0) {
                    this.$parent.internalBankAcc[this.edit.index] = this.bank;
                } else {
                    this.$parent.internalBankAcc.push(this.bank);
                }
                this.$parent.showAddBank = false;
                this.$parent.isEdit = {};
            },
        },
        created() {
            if (Object.keys(this.edit).length > 0) {
                this.bank.beneficiaryName = this.edit.beneficiaryName
                // this.bank.beneficiaryName = `${this.store.userDetail.firstName} ${this.store.userDetail.lastName}`;
                this.bank.accountNumber = this.edit.accountNumber;
                this.bank.bankName = this.edit.bankName;
                this.bank.code = this.edit.code;
                this.bank.countryIso = this.edit.countryIso;
                /*this.bank.bankAddress = this.edit.bankAddress*/
            } 
            // else {
            //     this.bank.beneficiaryName = `${this.store.userDetail.firstName} ${this.store.userDetail.lastName}`;
            // }

        },
    };
</script>